<template>
    <div class="emoji_picker" :style="[customStyle]">
        <div class="picker_container">
            <div class="category" v-for="category in categories" :key="`category_${category}`">
                <div class="emojis_container">
                    <button @click.prevent="handleEmojiClick(emoji)" v-for="(emoji, index) in category_emojis(category)" :key="`emoji_${index}`">
                        {{ emoji }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import data from "./emojis-data.json";

export default {
    props: {
        show_arrow: {
            type: Boolean,
            required: false,
            default: true,
        },
        customStyle:{
            type: Object,
            required: false,
        }
    },
    computed: {
        // 分类
        categories() {
            return Object.keys(data);
        },
        // 获取分类对应的表情
        category_emojis: () => (category) => {
            return Object.values(data[category]);
        },
    },
    methods: {
        // 将选中的表情信息发送给其它组件
        handleEmojiClick(emoji) {
            this.$emit("emoji_click", emoji);
            console.log("你点击了 => ", emoji);
        },
    },
};
</script>

<style scoped>
.emoji_picker,
.emoji_picker,
.picker_container {
  /* border-radius: 0.5rem; */
  background: white;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.picker_container {
  position: relative;
  /* padding: 1rem .5rem; */
  overflow: auto;
  z-index: 1;
}

.category {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 1rem; */
  color: rgb(169, 169, 169);
}

.emojis_container {
  /* display: flex;
  flex-wrap: wrap;
  justify-content: space-between; */
  display: grid;
  grid-template-columns: repeat(9,minmax(0,1fr));
}

.category button {
    cursor: pointer;
  margin: 0px 5px;
  /* margin-left: 0; */
  background: inherit;
  border: none;
  font-size: 20px;
  width: 27px;
  height: 45px;
  padding: 0;
}

.bottom_arrow {
  position: absolute;
  left: 50%;
  bottom: 0;
  /* width: 0.75rem;
    height: 0.75rem; */
  transform: translate(-50%, 50%) rotate(45deg);
  background: white;
}
</style>