<!--
 * @Author: dongjia
 * @Date: 2022-02-12 18:15:52
 * @LastEditTime: 2022-09-26 12:02:14
 * @LastEditors: zhengjiajun
 * @Description: 报名名单查看弹窗
 * @FilePath: \activity\components\ActivityList\JoinMemberDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog
    title="服务小记"
    :visible.sync="openDialog"
    width="500px"
    :show-close="true"
    class="dialog-vertical"
    @open="openDialogFn"
    :append-to-body="true"
  >
    <div class="dialog-content">
      <el-form
        label-position="right"
        label-width="80px"
        :model="remakeForm"
        @submit.native.prevent
      >
      <el-form-item label="咨询类型：">
          <el-select v-model="remakeForm.category_id" @change="surveyChange" placeholder="请选择">
            <el-option
              v-for="item in surveyList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="解决状态：">
          <el-select v-model="remakeForm.question_status" placeholder="请选择">
            <el-option
              v-for="item in questionStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
            show-word-limit
            :maxlength="100"
            clearable
            type="textarea"
            placeholder="请输入备注"
            v-model="remakeForm.remark"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer">
      <el-button @click="openDialog = false" size="small">取消</el-button>
      <el-button
        type="primary"
        :loading="loading"
        size="small"
        @click="saveForm"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { serviceCategory } from '@/modules/consult/api/consult-service-workbench'
export default {
  name: 'serviceNoteDialog',
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    category_id:String,
    category_name:String
  },
  data() {
    return {
      loading: false,
      detail: null,
      surveyList:[],
      // 修改备注表单
      remakeForm: {
        remark: '',
        question_status: 1,
        category_id: '',
        category_name:''
      },
      questionStatusList: [
        {
          value: 3,
          label: '未解决',
        },
        {
          value: 1,
          label: '已解决',
        },
      ],
    }
  },
  methods: {
    // 保存
    saveForm() {
      this.loading = true
      const postData = {
        ...this.remakeForm,
        chat_id: this.chat_id,
        user_id: this.user_id,
      }
      console.log(postData)
      this.$emit('endService', postData, () => {
        this.loading = false
        this.openDialog = false
      })
    },
    // 打开窗口触发
    openDialogFn() {
      this.remakeForm.remark = ''
      this.remakeForm.question_status = 1
      this.remakeForm.category_id = this.$props.category_id
      this.remakeForm.category_name =  this.$props. category_name
      if(this.surveyList.length==0){
        this.serviceCategory()
      }
    },
    serviceCategory(){
      serviceCategory().then(res=>{
        this.surveyList = res.data
      })
    },
    surveyChange(e){
      let index = this.surveyList .findIndex(item=>item.id==e)
      this.remakeForm.category_name = this.surveyList[index].name
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-textarea__inner {
  height: 150px;
}
</style>
