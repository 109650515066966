<!--
 * @Author: mulingyuer
 * @Date: 2021-09-16 15:12:54
 * @LastEditTime: 2021-10-21 17:57:51
 * @LastEditors: mulingyuer
 * @Description: 商协会大数据
 * @FilePath: \saas-admin-vue\src\modules\gov\views\big-data\index.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="big-data" :class="{ full: isFull }">
    <div class="header">
      <div>客服工作台</div>
      <!-- <div @click="isFull = !isFull"> 全屏</div> -->
    </div>
    <div
      class="content"
      :style="{
        padding: isFull ? '20px' : '0px',
      }"
      v-loading="loading"
    >
      <div class="flex main">
        <div class="left flex-col">
          <div class="input-box">
            <el-input
              v-model="user.form.keyword"
              size="small"
              prefix-icon="el-icon-search"
              placeholder="请输入"
              @keyup.enter.native="userRefresh"
            />
          </div>
          <div class="tab-box">
            <el-tabs v-model="user.form.tab" @tab-click="tabClick">
              <el-tab-pane
                v-for="tab in tabList"
                :key="tab.name"
                :label="`${tab.label}`"
                :name="tab.name"
              >
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="loading" v-loading="user.loading">
            <div
              class="list-box"
              v-infinite-scroll="userScroll"
              :infinite-scroll-disabled="!user.form.tab === 'finished'"
            >
              <el-empty
                description="暂无用户"
                v-if="!user.loading && user.list.length === 0"
              ></el-empty>
              <div
                v-for="item in user.list"
                class="flex-align-center user-box flex-1"
                :class="
                  user.activeUser.chat_id === item.chat_id ? 'is-active' : ''
                "
                @click="userClick(item)"
                :key="item.chat_id"
              >
                <div class="user-avatar">
                  <el-badge
                    class="badge-item"
                    :is-dot="!!item.service_unread_msg_count"
                  >
                    <el-image
                      style="width: 42px; height: 42px; border-radius: 999rem"
                      :src="item.user.avatar"
                      fit="cover"
                    ></el-image>
                  </el-badge>
                </div>
                <div class="flex-col user-info flex-1">
                  <div class="flex-align-center flex-between">
                    <div class="flex-1 flex-align-center">
                      <div class="user-name">{{ item.user.nickname }}</div>
                    </div>
                    <div class="user-time">
                      {{ toggleTime(item.latest_msg_time) }}
                    </div>
                  </div>
                  <div class="flex-align-center flex-between">
                    <div v-if="user.form.tab === 'auto'" class="user-text">
                      {{ item.description }}
                    </div>
                    <div class="flex" style="gap: 4px" v-else>
                      <el-tag
                        v-if="item.solving_count"
                        size="mini"
                        type="warning"
                        >解决中&nbsp;{{ item.solving_count }}</el-tag
                      >
                      <el-tag
                        v-if="item.waiting_chat_count"
                        size="mini"
                        type="danger"
                        >未沟通&nbsp;{{ item.waiting_chat_count }}</el-tag
                      >
                      <el-tag v-if="item.chatting_count" size="mini" type="info"
                        >沟通中&nbsp;{{ item.chatting_count }}</el-tag
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="middle">
          <template>
            <div class="middle-header">
              <template v-if="user.activeUser.chat_id && user.activeUser.user">
                <div class="user-info flex-align-center">
                  <el-image
                    :src="user.activeUser.user.avatar"
                    style="width: 42px; height: 42px; border-radius: 999rem"
                    fit="cover"
                  ></el-image>
                  <div class="user-name">
                    {{ user.activeUser.user.nickname }}
                  </div>
                  <!-- <div v-if="admin_user" style="margin-left: 20px">
                    <el-tag v-if="admin_user === 2" type="warning"
                      >待沟通</el-tag
                    >
                    <el-tag v-else-if="admin_user === 3">沟通中</el-tag>
                    <el-tag v-else type="danger">已结束</el-tag>
                  </div> -->
                </div>
                <div v-if="user.activeUser.chat_type === 'auto'">
                  <el-button
                    type="danger"
                    @click="
                      openMessage({
                        chat_id: user.activeUser.chat_id,
                        user_id: user.activeUser.user.id,
                        is_ai: 1,
                      })
                    "
                    plain
                    >AI 记录</el-button
                  >
                </div>
                <el-radio-group
                  v-else-if="questionsList.length"
                  v-model="questionsStatus"
                  size="small"
                  class="questions-status"
                >
                  <el-radio-button
                    :disabled="
                      questionsList.findIndex(
                        (t) => t.question_status_value === item.value
                      ) === -1
                    "
                    v-for="item in questionsStatusList"
                    :key="item.value"
                    :label="item.value"
                  >
                    <el-badge
                      class="badge-item"
                      :is-dot="
                        questionsList.findIndex(
                          (t) =>
                            t.question_status_value === item.value &&
                            t.service_unread_msg_count
                        ) !== -1
                      "
                    >
                      {{ item.label }}
                    </el-badge>
                  </el-radio-button>
                </el-radio-group>
              </template>
            </div>
            <div
              class="questions-list flex-col"
              v-if="questionsListComputed.length"
            >
              <div class="questions-list-absolute">
                <el-tabs
                  v-model="user.activeUser.chat_id"
                  @tab-click="questionsClick"
                >
                  <el-tab-pane
                    v-for="item in questionsListComputed"
                    :key="item.chat_id"
                    :label="item.category_name"
                    :name="item.chat_id"
                  >
                    <span slot="label">
                      <el-badge
                        :is-dot="!!item.service_unread_msg_count"
                        class="badge-item"
                        >{{ item.category_name }}
                      </el-badge></span
                    >
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
            <template v-if="user.activeUser.chat_id && user.activeUser.user">
              <MessageList
                ref="messageListRefs"
                :leftAvatar="user.activeUser.user.avatar"
                :rightAvatar="serviceConfig.avatar"
                :message="message"
                @messageScroll="messageScroll"
                :customStyle="{
                  height: questionsListComputed.length
                    ? 'calc(100% - 166px - 72px - 40px)'
                    : 'calc(100% - 166px - 72px)',
                  'padding-bottom': admin_user === 1 ? '72px' : '20PX',
                }"
                @openServiceDialog="
                  (chat_id) =>
                    openServiceDialog({
                      chat_id,
                      user_id: user.activeUser.user.id,
                    })
                "
              />

              <div v-loading="admin_user === -1" class="input-box">
                <div
                  v-if="
                    status === 4 && user.activeUser.chat_type === 'question'
                  "
                  class="been-added flex-center"
                >
                  个案服务已结束
                </div>
                <div v-else-if="admin_user === 0" class="start-box flex-center">
                  <el-button type="primary" @click="start">接入服务</el-button>
                </div>
                <template v-else-if="admin_user === 1">
                  <div class="end-service-btn flex-align-center">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="请及时结束服务，服务中用户无法使用自动回复功能，如超过5分钟未回复将自动结束服务"
                      placement="top"
                    >
                      <i
                        v-if="user.activeUser.chat_type === 'auto'"
                        class="el-icon-warning-outline"
                      />
                    </el-tooltip>
                    <el-button
                      type="danger"
                      size="small"
                      @click="clickEndService"
                      plain
                      >结束服务</el-button
                    >
                  </div>

                  <MessageInput
                    @send="sendMessage"
                    @transferService="transferService"
                    :showTransferService="true"
                  />
                </template>
                <div
                  v-else-if="admin_user === 2"
                  class="been-added flex-center"
                >
                  已由其他客服接入
                </div>
              </div>
            </template>
            <el-empty v-else description="暂无消息"></el-empty>
          </template>
        </div>
        <div class="right flex-col">
          <template v-if="user.activeUser.chat_id && user.activeUser.user">
            <UserInfo
              :chat_id="user.activeUser.chat_id"
              :user_id="user.activeUser.user.id"
              :status="status"
              @updateUserInfoStatus="updateUserInfoStatus"
              :admin_user="admin_user"
              :leftAvatar="user.activeUser.user.avatar"
            ></UserInfo>
          </template>
          <el-empty v-else></el-empty>
        </div>
      </div>
      <SurveyListDialog
        :chat_id="surveyDialog.chat_id"
        :user_id="surveyDialog.user_id"
        v-model="surveyDialog.show"
      />
      <MessageListDialog
        :is_ai="messageListDialog.is_ai"
        v-model="messageListDialog.show"
        :chat_id="messageListDialog.chat_id"
        :user_id="messageListDialog.user_id"
        :leftAvatar="messageListDialog.leftAvatar"
      />
      <ServiceNoteDialog
        v-model="serviceNoteDialog.show"
        :category_id="category_id"
        :category_name="category_name"
        @endService="endService"
      />
    </div>
  </div>
</template>

<script>
import {
  getUserList,
  sendMessage,
  endService,
  transferService,
  getQuestions,
  start,
} from '@/modules/consult/api/consult-service-workbench'
import { getServiceConfig } from '@/modules/consult/api/consult-service/config'
import day from '@/modules/consult/mixin/day'
import MessageListMixin from '@/modules/consult/components/consult-service-workbench/MessageList/mixin.js'
import MessageList from '@/modules/consult/components/consult-service-workbench/MessageList/index.vue'
import UserInfo from '@/modules/consult/components/consult-service-workbench/UserInfo/index.vue'
import SurveyListDialog from '@/modules/consult/components/consult-service-workbench/SurveyListDialog.vue'
import MessageInput from '@/modules/consult/components/consult-service-workbench/MessageInput.vue'
import MessageListDialog from '@/modules/consult/components/consult-service/consult-list/MessageListDialog.vue'
import ServiceNoteDialog from '@/modules/consult/components/consult-service-workbench/ServiceNoteDialog.vue'
import AiAvatar from '@/modules/consult/assets/images/ai-avatar.png'
export default {
  mixins: [day, MessageListMixin],
  components: {
    MessageInput,
    MessageList,
    UserInfo,
    SurveyListDialog,
    MessageListDialog,
    ServiceNoteDialog,
  },
  data() {
    return {
      loading: false, //加载中
      isFull: true, //是否全屏显示
      tabList: [
        {
          label: '自动回复',
          name: 'auto',
          num: 0,
        },
        {
          label: '人工客服',
          name: 'question', //
          num: 0,
        },
      ],
      // 用户
      user: {
        list: [],
        loading: true,
        noMore: false,
        // 选中用户
        activeUser: {},
        form: {
          tab: 'auto', // question,auto
          keyword: '', //暂只支持搜用户名
          page: 1, // 只有已结束的才需要分页
          page_size: 50,
        },
      },
      // 客服 id
      admin_user_id: '',
      // 客服配置
      serviceConfig: {},
      // 接入状态
      admin_user: -1,
      //服务状态
      status: -1,
      // 服务分类
      category_id: '',
      // 服务分类名称
      category_name: '',
      //问卷信息
      surveyDialog: {
        show: false,
        user_id: '',
        chat_id: '',
      },
      // 消息弹窗
      messageListDialog: {
        show: false,
        chat_id: '',
        user_id: '',
        leftAvatar: '',
      },
      // 个案列表
      questionsList: [],
      // 个案状态筛选
      questionsStatus: 'waiting_chat',
      questionsStatusList: [
        {
          label: '待沟通',
          value: 'waiting_chat',
        },
        {
          label: '沟通中',
          value: 'chatting',
        },
        {
          label: '解决中',
          value: 'solving',
        },
      ],
      // 服务小记弹窗
      serviceNoteDialog: {
        show: false,
        question_status: 1,
      },
    }
  },
  computed: {
    questionsListComputed() {
      return this.questionsList.filter(
        (item) => item.question_status_value == this.questionsStatus
      )
    },
  },
  methods: {
    // 更新接入状态
    updateUserInfoStatus(userInfo) {
      const { admin_user, status, category_id, category_name } = userInfo
      this.admin_user = admin_user
      this.status = status
      this.category_id = category_id
      this.category_name = category_name
    },
    //接入服务
    start() {
      start({
        user_id: this.user.activeUser.user.id,
        chat_id: this.user.activeUser.chat_id,
      }).then((res) => {
        this.$message.success('接入服务成功')
      })
    },
    //消息记录
    openMessage(row) {
      this.messageListDialog.is_ai = row.is_ai
      this.messageListDialog.show = true
      this.messageListDialog.chat_id = row.chat_id
      this.messageListDialog.user_id = row.user_id
      this.messageListDialog.leftAvatar = AiAvatar
    },
    // 问卷信息
    openServiceDialog({ chat_id, user_id }) {
      this.surveyDialog.show = true
      this.surveyDialog.user_id = user_id
      this.surveyDialog.chat_id = chat_id
    },
    // tab 栏切换
    tabClick() {
      this.userRefresh()
    },
    // 获取个案列表
    async getQuestions(user_id) {
      let res = await getQuestions({ user_id })
      this.questionsList = res.data.data
      return this.questionsList
    },
    // 用户列表滚动
    userScroll() {
      return
      if (
        this.user.loading ||
        this.user.noMore ||
        this.user.form.tab !== 'finished' ||
        this.user.list.length === 0
      )
        return
      this.user.form.page++
      this.getUserList()
    },
    // 个案列表点击
    questionsClick(e) {
      this.userClick(this.user.activeUser)
    },
    // 用户列表点击
    async userClick(user) {
      if (user.chat_id) {
        // 重新修改接入状态
        this.admin_user = -1
        // 个人服务
        if (user.chat_type === 'question') {
          if (
            user.user.id !== this.user.activeUser?.user?.id ||
            !this.questionsList.length
          ) {
            this.questionsList = []
            let list = await this.getQuestions(user.user.id)
            if (list[0]) {
              user.chat_id = list[0]?.chat_id
              this.questionsStatus = list[0]?.question_status_value
            }
          }
        } else {
          this.questionsList = []
        }
        localStorage.setItem('service-user', JSON.stringify(user))
        this.user.activeUser = user
        this.messageRefresh({
          user_id: this.user.activeUser.user.id,
          chat_id: this.user.activeUser.chat_id,
        })
      } else {
        this.message.list = []
        this.user.activeUser = user
        localStorage.setItem('service-user', JSON.stringify({}))
      }
    },
    // 用户列表刷新
    userRefresh() {
      this.user.loading = false
      this.user.noMore = false
      this.user.form.page = 1
      this.user.list = []

      this.getUserList()
    },
    // 更新用户列表
    userUpdate(message) {
      const index = this.user.list.findIndex(
        (t) => t.user.id === message.user_id
      )
      // 更新个案列表消息提示
      if (this.questionsList.length) {
        this.questionsList = this.questionsList.map((t) => {
          if (t.chat_id === message.chat_id) {
            t.service_unread_msg_count = message.is_my ? 0 : 1
          }
          return t
        })
      }
      if (index !== -1) {
        const old_user = this.user.list[index]
        // 更新消息提示
        let service_unread_msg_count = old_user.service_unread_msg_count
        if (message.chat_type !== this.user.form.tab) {
          return
        } else {
          service_unread_msg_count = message.service_unread_msg_count
        }
        let description = old_user.description
        switch (message.type) {
          case 'text': {
            description = message.content.text
            break
          }
        }
        const user = {
          ...old_user,
          latest_msg_time: message.create_time,
          description,
          service_unread_msg_count,
        }
        this.user.list.splice(index, 1, user)
        this.user.list = this.user.list.sort((a, b) => {
          return b.latest_msg_time - a.latest_msg_time
        })
      }
    },
    // 获取用户列表
    getUserList() {
      this.user.loading = true
      getUserList(this.user.form)
        .then((res) => {
          this.user.list.push(...res.data.data)
          this.admin_user_id = res.data.admin_user_id
          if (
            this.user.form.tab === 'finished' &&
            res.data.data.length < this.user.form.page_size
          ) {
            this.user.noMore = true
          }
          this.user.loading = false
          this.user.finishedLoading = false
        })
        .catch(() => {
          this.user.loading = false
        })
    },
    //发送消息
    sendMessage({ type, content }) {
      sendMessage({
        type,
        content,
        user_id: this.user.activeUser.user.id,
        chat_id: this.user.activeUser.chat_id,
      }).then((res) => {})
    },
    // 获取客服配置
    getServiceConfig() {
      getServiceConfig().then((res) => {
        this.serviceConfig = res.data
      })
    },
    // 点击结束服务
    clickEndService() {
      if (this.user.activeUser.chat_type === 'auto') {
        this.$confirm('是否结束服务?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then((res) => {
          this.endService()
        })
      } else {
        this.serviceNoteDialog.show = true
      }
    },
    // 结束服务
    endService(form = {}, callback = () => {}) {
      endService({
        ...form,
        chat_id: this.user.activeUser.chat_id,
        user_id: this.user.activeUser.user.id,
      }).then((res) => {
        callback()
        this.$message.success('结束服务成功')
      })
    },
    // 转接服务
    transferService(admin_user_id) {
      transferService({
        chat_id: this.user.activeUser.chat_id,
        user_id: this.user.activeUser.user.id,
        admin_user_id,
      }).then(() => {
        this.admin_user = 2
        this.$message.success('转接成功')
      })
    },
  },
  mounted() {},
  async created() {
    this.loading = false
    this.getUserList()
    this.getServiceConfig()

    // 接收消息
    this.$bus.on('consult_chat_new_msg', (data) => {
      if (data.chat_id === this.user.activeUser.chat_id) {
        this.message.list.push(data)
        let messageListRefs = this.$refs.messageListRefs.$el
        if (messageListRefs) {
          this.$nextTick(() => {
            messageListRefs.scrollTop = messageListRefs.scrollHeight
          })
        }
        if (data.admin_user_id) {
          if (data.admin_user_id === this.admin_user_id) {
            this.admin_user = 1
          } else {
            this.admin_user = 2
          }
        }
      }
      this.userUpdate(data)
    })
    // 左侧用户列表更新
    this.$bus.on('consult_chat_update_chat', (data) => {
      if (this.user.form.tab === data.chat_type) {
        if (this.user.list.some((t) => t.user.id === data.user.id)) {
          this.user.list = this.user.list.map((t) => {
            if (t.user.id === data.user.id) {
              // user无需修改
              return {
                ...t,
                chat_id: data.chat_id,
                solving_count: data.solving_count,
                waiting_chat_count: data.waiting_chat_count,
                chatting_count: data.chatting_count,
                chat_id: data.chat_id,
                latest_msg_time: data.latest_msg_time
                  ? data.latest_msg_time
                  : t.latest_msg_time,
              }
            }
            return t
          })
          this.user.list = this.user.list.sort((a, b) => {
            return b.latest_msg_time - a.latest_msg_time
          })
        } else {
          this.user.list.unshift(data)
        }
      }
      if (
        this.user.activeUser.user.id === data.user.id &&
        this.user.activeUser.chat_type === 'question'
      ) {
        this.getQuestions(data.user.id).then((list) => {
          if (this.user.activeUser.chat_id === data.chat_id) {
            list.forEach((item) => {
              if (item.chat_id === data.chat_id) {
                this.questionsStatus = item.question_status_value
              }
            })
          }
        })
      }
    })
    // 结束服务
    this.$bus.on('consult_chat_finish', (data) => {
      this.questionsList = this.questionsList.filter(
        (t) => t.chat_id !== data.chat_id
      )
      if (this.user.activeUser.chat_id === data.chat_id) {
        if (this.user.activeUser.chat_type === 'auto') {
          this.admin_user = 0
        } else {
          this.status = 4
          this.user.activeUser.chat_id = ''
          this.$nextTick(() => {
            this.user.activeUser.chat_id = data.chat_id
          })
        }
        this.message.list.push(data)
        let messageListRefs = this.$refs.messageListRefs.$el
        if (messageListRefs) {
          this.$nextTick(() => {
            messageListRefs.scrollTop = messageListRefs.scrollHeight
          })
        }
      }
    })
    // // 接收到转接用户
    this.$bus.on('consult_chat_change', (data) => {
      if (
        data.admin_user_id === this.admin_user_id &&
        data.chat_id === this.user.activeUser.chat_id
      ) {
        this.admin_user = 1
      }
    })
    const user = JSON.parse(localStorage.getItem('service-user'))
    if (user && user.chat_id) {
      this.userClick(user)
    }
  },
  beforeDestroy() {
    this.$bus.off('consult_chat_new_msg')
    this.$bus.off('consult_chat_finish')
    this.$bus.off('consult_chat_update_chat')
    this.$bus.off('consult_chat_change')
    // 开发时记得注释掉不然会频繁触发
    this.$store.commit('socket/closeSocket')
  },
}
</script>

<style lang="scss" scoped>
.big-data {
  background-color: $bg-color;
  margin: -$space;
  // border-radius: $radius;
  // overflow: hidden;
  overflow-x: auto;
  &.full {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    margin: 0;
    padding: $space;
    z-index: 99;
    padding: 0;
  }
  .header {
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: #b3b3b3;
    font-size: 18px;
    line-height: 18px;
    font-weight: bold;
  }
  .content {
    padding: 20px;
    min-height: calc(100vh - #{$header-h});
    max-width: 2000px;
    min-width: 1200px;
    margin: auto;
    .main {
      height: calc(100vh - var(--header-h) - 40px);
      align-items: stretch;
      .loading {
        height: 100%;
        overflow: hidden;

        // padding-bottom: 65px; /* 因为有个 tab-box */
      }
      .tab-box {
        padding: 20px;
        padding-top: 16px;
        padding-bottom: 10px;
        ::v-deep.el-tabs__nav-wrap::after {
          background-color: transparent;
        }
        ::v-deep.el-tabs__item {
          color: #cbcbcb;
          font-weight: 400;
          width: calc(100% / 2);
          text-align: center;
          padding: 0px;
        }
        ::v-deep.el-tabs__item.is-active {
          color: #3576ff;
        }
        ::v-deep.el-tabs__nav {
          width: 100%;
        }
      }
      .left {
        background-color: #fff;
        flex: 1;
        border-right: 1px solid #eff2f6;
        .input-box {
          padding: 20px;
          padding-bottom: 0px;
        }

        .list-box {
          width: 100%;
          padding: 0 10px;
          overflow: auto;
          height: 100%;
          .user-box {
            padding: 10px;
            width: 100%;
            height: 64px;
            border-radius: 4px;
            border: 0.5px solid transparent;
            .user-avatar {
              margin-right: 12px;
              .badge-item {
                ::v-deep.el-badge__content {
                  left: -10px;
                  top: 5px;
                  height: 10px;
                  width: 10px;
                }
              }
            }
            .user-info {
              justify-content: space-between;
              height: 100%;
              .user-name {
                height: 21rpx;
                font-weight: 400;
                font-size: 14px;
                color: #1a1a1a;
                @include clamp(1);
              }
              .user-time {
                height: 21rpx;
                font-weight: 400;
                font-size: 12px;
                color: #cbcbcb;
              }
              .user-text {
                height: 18px;
                font-size: 12px;
                font-weight: 400;
                color: #808080;
                @include clamp(1);
              }
              .user-unread {
              }
            }
            &.is-active {
              border: 0.5px solid rgba(53, 118, 255, 0.1);
              background: linear-gradient(
                  0deg,
                  rgba(53, 118, 255, 0.05) 0%,
                  rgba(53, 118, 255, 0.05) 100%
                ),
                #fff;
            }
          }
        }
      }
      .middle {
        // background-color: #dfa;
        flex: 2;
        .middle-header {
          height: 72px;
          display: flex;
          align-items: center;
          padding: 0 18px;
          justify-content: space-between;
          background-color: #fff;
          border-bottom: 1px solid #eff2f6;
          .user-info {
            .user-name {
              margin-left: 9px;
              font-size: 14px;
              font-weight: 400;
            }
          }
          .questions-status {
            .badge-item {
              ::v-deep.el-badge__content {
                top: -10px;
                right: -10px;
                height: 10px;
                width: 10px;
                z-index: 99;
              }
            }
          }
        }
        .questions-list {
          position: relative;
          width: 100%;
          height: 40px;
          .questions-list-absolute {
            padding: 0px 20px;
            background-color: #fff;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            ::v-deep.el-tabs__nav-wrap::after {
              background-color: transparent;
            }
            .badge-item {
              ::v-deep.el-badge__content {
                top: 10px;
                right: 0px;
                height: 10px;
                width: 10px;
              }
            }
          }
        }
        .input-box {
          height: 166px;
          background-color: #fff;
          position: relative;
          .end-service-btn {
            position: absolute;
            top: calc(-32px + -15px);
            right: 25px;
            .el-icon-warning-outline {
              margin-right: 10px;
              color: #cbcbcb;
            }
          }
        }
        .start-box {
          height: 100%;
        }
        .been-added {
          height: 100%;
        }
      }
      .right {
        background-color: #fff;
        border-left: 1px solid #eff2f6;
        flex: 1;
      }
    }
  }
}
</style>
