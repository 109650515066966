<template>
  <div class="message-input">
    <div class="function-list">
      <div class="flex">
        <div class="icon">
          <el-popover placement="top" width="400" trigger="click">
            <div style="height: 200px">
              <EmojiPicker @emoji_click="emoji_click" />
            </div>
            <img
              slot="reference"
              class="icon"
              src="@/modules/consult/assets/images/function-emojis-icon.png"
          /></el-popover>
        </div>

        <img
          @click="image.show = true"
          class="icon"
          src="@/modules/consult/assets/images/function-img-icon.png"
        />
        <div class="icon">
          <el-upload
            :show-file-list="false"
            action="#"
            :http-request="handleUpload"
            :before-upload="beforeUpload"
          >
            <img
              class="icon"
              src="@/modules/consult/assets/images/function-file-icon.png"
            />
          </el-upload>
        </div>
        <img
          @click="address.show = true"
          class="icon"
          src="@/modules/consult/assets/images/function-location-icon.png"
        />
      </div>
      <div class="icon" v-if="showTransferService">
        <el-popover placement="top" width="250" v-model="visible">
          <div
            style="
              width: 237px;
              height: 292px;
              overflow: auto;
              padding: 10px 20px;
            "
          >
            <div
              class="flex-align-center"
              :style="{
                marginTop: index ? '26px' : '0',
              }"
              v-for="(item, index) in adminUsers.list"
              :key="item.id"
            >
              <!-- <el-image
                :src="item.avater"
                style="
                  width: 32px;
                  height: 32px;
                  border-radius: 999em;
                  margin-right: 10px;
                "
                fit="cover"
              /> -->
              <div class="name flex-1">{{ item.nickname }}</div>
              <el-button type="primary" size="mini" @click="transferService(item.id)"
                >转接</el-button
              >
            </div>
          </div>
          <img
            @click="getAdminUsers"
            slot="reference"
            class="icon"
            src="@/modules/consult/assets/images/function-user-icon.png"
          />
        </el-popover>
      </div>
    </div>
    <el-input
      class="input"
      type="textarea"
      placeholder="请输入..."
      @keyup.enter.native="textSend"
      v-model="textarea"
    ></el-input>
    <AddressSelector
      v-model="address.show"
      :detailForm="address.form"
      @change="addressSend"
    ></AddressSelector>
    <media-selector
      v-model="image.show"
      :max="1"
      type="image"
      @select="imageSend"
    >
    </media-selector>
  </div>
</template>

<script>
import AddressSelector from '@/modules/consult/components/consult-service-workbench/AddressSelector.vue'
import MediaSelector from '@/modules/common/components/MediaSelector'
import filesUpload from '@/base/utils/upload4'
import EmojiPicker from '@/modules/consult/components/consult-service-workbench/EmojiPicker'
import { getFileType } from '@/base/utils/tool'
import { getAdminUsers } from '@/modules/consult/api/consult-service-workbench'
export default {
  props:{
    showTransferService:{
      type:Boolean,
      default:false
    }
  },
  components: {
    AddressSelector,
    MediaSelector,
    EmojiPicker,
  },
  data() {
    return {
      textarea: '',
      visible: false,
      address: {
        show: false,
        form: {
          lat: '',
          lng: '',
          address: '',
          remark: '',
          start_time: -1,
          end_time: -1,
          activityTime: [],
          addressName: '',
        },
      },
      image: {
        show: false,
      },
      adminUsers: {
        loading: false,
        list: [],
      },
    }
  },
  methods: {
    textSend() {
      let text = this.textarea.substring(0, this.textarea.length - 1)
      this.$emit('send', { type: 'text', content: { text } })
      this.textarea = ''
    },
    addressSend(data) {
      this.$emit('send', {
        type: 'location',
        content: {
          address: data.address,
          latitude: data.lat,
          longitude: data.lng,
          name: data.address_name,
        },
      })
    },
    imageSend(list) {
      this.$emit('send', {
        type: 'image',
        content: {
          image: list[0].url,
        },
      })
    },
    emoji_click(e) {
      this.textarea += e
    },
    getAdminUsers() {
      this.adminUsers.loading = true
      if (this.adminUsers.list.length) {
        this.adminUsers.loading = false
        return
      }

      getAdminUsers()
        .then((res) => {
          this.adminUsers.list = res.data
          this.adminUsers.loading = false
        })
        .catch(() => {
          this.adminUsers.loading = false
        })
    },
    transferService(id) {
      this.$emit('transferService', id)
      this.adminUsers.show = false
    },

    /**
     * @param file
     * @param silent  是否关闭错误提示
     * */
    beforeUpload(file, silent = false) {
      /* 文件类型判断 */
      const myFileType = getFileType(file?.name)
      const fileTypeSupported = [
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'jpg',
        'jpeg',
        'png',
      ].includes(myFileType)
      // 大小判断
      const is10M = file.size / 1024 / 1024 < 150
      if (!fileTypeSupported && !silent) {
        this.$message.error('上传文件类型仅支持 word、excel、pdf、jpg、png !')
      } else if (!is10M && !silent) {
        // 大小不匹配
        this.$message.error('上传文件大小不能超过 150MB !')
      }
      // 返回 false 阻断 true 正常上传
      return is10M && fileTypeSupported
    },
    handleUpload(file) {
      /* 上传成功后才将{name: '', url: ''}push到fileList中，会有成功图标 */
      // this.fileList.push({name: file.file.name, url: ''})
      filesUpload({
        configApi: '/admin/admin/media/requestUpload',
        data: file.file,
        progress: (n) => {
          // 更新 el-upload file-list 自带的进度条数值
          file.onProgress({ percent: n })
        },
      })
        .then((res) => {
          console.log(res)
          this.$emit('send', {
            type: 'file',
            content: {
              url: res.data.url,
              name: res.data.title,
              type: res.data.type === 2 ? 'image' : 'file',
            },
          })
          // this.$set(this.form, files, [...this.ActivityForm.files, {...res.data, name: file.file.name}])
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.message-input {
  padding: 16px;
  padding-right: 0;
  height: 100%;
  background-color: #fff;
  .function-list {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 16px;
    .icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    .icon + .icon {
      margin-left: 12px;
    }
  }
  .input {
    ::v-deep.el-textarea__inner {
      padding: 16px 0;
      border: none;
      resize: none;
      height: 110px;
    }
  }
}
</style>
